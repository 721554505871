<template>
  <div>
    <y_choose_member @change="choose">
      <el-button>{{ btnTxt }}</el-button>
    </y_choose_member>
  </div>
</template>

<script>
import y_choose_member from "@/components/user/YUserChoose";
export default {
  name: "ManageChoose",
  components: {y_choose_member},
  model:{
    prop:"modelVal",
    event:"change"
  },
  props:{
    modelVal:{
      type:Number,
      default:0,
    }
  },
  watch:{
    modelVal(){
      this.$u.api.user.one({id:this.modelVal}).then(res=>{
        this.u = res;
      })
    },
  },
  data() {
    return {
      u:{},
    }
  },
  computed:{
    btnTxt(){
      if(!this.u.id)return "选择用户";
      return  `#${this.u.id}:${this.u.nickname}`;
    }
  },
  methods:{
    choose(e){
      this.$emit("change",e.id);
      this.u = e;
    },
  },
}
</script>

<style scoped>

</style>